/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import transfersbyWelcomePickupsLogo from "assets/images/landing-pages/transfersbyWelcomePickupsLogo";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { getCurrentLanguage, addScript, removeScript } from "./helpers";
import { TransparentLink } from "./styledComponents";
import { GonProvider } from "../../helpers/GonProviders";

const { dropoff, checkin_date, current_property: { id, hide_lp_powered_by_header, quote_form_host: quote, branding_theme: { main_color: mainColor } } } = GonProvider;
const isPreview = window.location?.pathname?.split("/")?.pop() === "preview";
let savedWidgetRouteSelectorValue;
let isFirstRender = true;

const WidgetBackground = styled.div(({ scroll, scrolledTop, rightOffset,type }) => (`
	position: ${scroll ? "fixed" : "absolute"};
	left: ${scroll ? `${rightOffset-360}px` : "auto"};
  right:0px;
	top: ${scrolledTop};
	box-shadow: 0 5px 20px 0 rgba(45,59,78,0.08);
	border-radius: 15px;
	background: #fff;
	max-width: 360px;
	z-index: 100;
  bottom:0;
  transform:translateY(-50%);
  bottom:0;
  height:200px;
  @media(max-width:1200px){

  }
  @media(max-width:1024px){
    right:0px;
  }
	@media (max-width: 991px){
    left:0;
    right:0;
    margin-top:${type==="hotel" ? "-80px" : "-60px"}!important;
    position:relative;
    margin: auto;
    height: auto;
    transform: none;
    margin-left:auto;
    margin-right:auto;
    height:auto;
    z-index:9;
    }
@media(max-width:991px){
  z-index:8;
}
	@media (max-width: 768px){
    margin-top:-30px!important;


  }

	
`));

const WelcomePickupsLogo = styled(TransparentLink)`
	display: none;

	img {
		width: 190px;
		margin-bottom: 30px;
	}

	@media (max-width: 768px){
		display:block;
    }

`;

const WidgetContainer = (props) => {
	const { type = "hotel" } = props;

  const [scroll, setScroll] = useState(false);
  const [scrolledTop, setScrolledTop] = useState("50%");
  const [rightOffset, setRightOffset] = useState(0);
	
	const [widgetRouteSelectorLocked, setWidgetRouteSelectorLocked] = useState(false);
	const [widgetRouteSelectorLockedValue, setWidgetRouteSelectorLockedValue] = useState();
	// We need to update this every time we want to rerender the widget
	const [widgetReloadKey, setWidgetReloadKey] = useState(Date.now());

	const widgetRouteSelectorValue = useFeatureFlagEnabled("route_selector");
	// Currently we only want to show the experiment on hotels
	savedWidgetRouteSelectorValue = type === "hotel" ? widgetRouteSelectorValue : false;

	const isWidgetRouteSelectorActive = widgetRouteSelectorLocked ? widgetRouteSelectorLockedValue : savedWidgetRouteSelectorValue;
	// Only render widget when we are sure what version we should render
	// Using the locked value means that we show the default version because posthog failed to set the value
	const isWidgetAllowedToRender = savedWidgetRouteSelectorValue !== undefined || widgetRouteSelectorLocked;

  
  const listenToScroll = () => {
    const fullHeight = Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );

    if (window.pageYOffset >= 80) {
      setScroll(true);
      setScrolledTop("120px");
    }
    if (window.pageYOffset < 80) {
      setScroll(false);
      setScrolledTop("50%");
    }
    if (window.pageYOffset >= fullHeight - 850) {
      setScroll(false);
      setScrolledTop(`${fullHeight - 800}px`);
    }
  };

	const loadWidget = () => {
		let widgetProps = {};
		const date = checkin_date || "";
		const { widget_tracking_info } = GonProvider;
		
		if (type === "bnbOrg") {
			widgetProps = {
				"no_card": "",
				"fluid": "0",
				"property_group_id": id,
				"to_location": "{\"city\":\"bnb organisation\"}",
				"locale": getCurrentLanguage(),
				"button_color": mainColor,
				"date": date
			};
		} else {
			const { current_property: { location_id: toLocationId } } = GonProvider;
			widgetProps = {
				"from_location_id": dropoff ? toLocationId : "",
				"to_location_id": dropoff ? "" : toLocationId,
				"no_card": "",
				"fluid": "0",
				"partner_id": id,
				"locale": getCurrentLanguage(),
				"button_color": mainColor,
				"quote_url": quote,
				"date": date
			};

			if (isWidgetRouteSelectorActive) {
				widgetProps = {
					...widgetProps,
					"experiments": { "route_selector": true },
					"tracking_info": { "enabled":"true" }
				};
			}
		}

		widgetProps = { ...widgetProps, tracking_info: { ...widget_tracking_info } };

		if (window.innerWidth > 991 && !isPreview) {
			window.addEventListener("scroll", listenToScroll);
		}
		
		const widget = document.getElementById("welcomepickups");

		const widgetPropsEl = document.createElement("script");
		widgetPropsEl.type = "text/props";
		widgetPropsEl.id = "widget-props";
		widgetPropsEl.innerHTML = JSON.stringify(widgetProps);
		widget.appendChild(widgetPropsEl);
		addScript(
      process.env.BOOKING_FORM_JS_SOURCE_URL,
      () => {},
      "welcome-widget",
      true,
      () => {
        console.log("widget script loaded");
      }
    );
	};
  
  useEffect(() => {
		if (isWidgetAllowedToRender) {
			loadWidget();
		}

		setRightOffset(document.getElementById("pid").getBoundingClientRect().left);
    
    // Cleanup function to remove event listener
    return () => {
      if (window.innerWidth > 991 && !isPreview) {
        window.removeEventListener("scroll", listenToScroll);
      }
			removeScript("welcome-widget");
    };
  }, [widgetReloadKey]);

	useEffect(() => {
		if (isWidgetAllowedToRender && !isFirstRender) {
			setWidgetReloadKey(Date.now());
		}
	}, [type, isWidgetRouteSelectorActive, isWidgetAllowedToRender])
	
	useEffect(() => {
		let widgetExperimentsTimeout;
		if (window.posthogLoaded) {
			// Sets the final value of the feature flag after the timeout has passed
			// and only if posthog failed to set the value.
			const widgetExperimentsTimeoutFunc = () => {
				if (savedWidgetRouteSelectorValue === undefined) {
					setWidgetRouteSelectorLocked(true);
					setWidgetRouteSelectorLockedValue(false);
				}
			};

			// We wait 3 seconds before finalizing the feature flag value.
			// After 3 seconds we lock its value and we allow the widget to load.
			widgetExperimentsTimeout = setTimeout(widgetExperimentsTimeoutFunc, 3000);
		}

		isFirstRender = false;

		return () => {
			clearTimeout(widgetExperimentsTimeout);
		}
	}, [window.posthogLoaded]);
  
  return (
    <WidgetBackground id="pid" rightOffset={rightOffset} scroll={scroll} type={type} scrolledTop={scrolledTop} key={widgetReloadKey}>
      <div id="welcomepickups" />
      {!hide_lp_powered_by_header &&
        <WelcomePickupsLogo mobileHide>
          <LazyLoadImage alt="welcome logo" src={transfersbyWelcomePickupsLogo} />
        </WelcomePickupsLogo>
      }
    </WidgetBackground>
  );
};

export default WidgetContainer;