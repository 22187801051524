import greeceFlag from "assets/images/landing-pages/language-flags/greeceFlag";
import franceFlag from "assets/images/landing-pages/language-flags/franceFlag";
import spainFlag from "assets/images/landing-pages/language-flags/spainFlag";
import germanyFlag from "assets/images/landing-pages/language-flags/germanyFlag";
import italyFlag from "assets/images/landing-pages/language-flags/italyFlag";
import portugalFlag from "assets/images/landing-pages/language-flags/portugalFlag";
import ukFlag from "assets/images/landing-pages/language-flags/ukFlag";
import { GonProvider } from "../../helpers/GonProviders";

const { sanitized_language_path: languagePath } = GonProvider;
/**
 * @param code
 * Navigate to chosen language page
 */
function changeLanguage(code) {

    if (languagePath) {
        window.location.replace(`/${code}${languagePath}`);
        return;
    }

    const url = window.location.href;
    const urlArray = url.split("/")

    urlArray.splice(0, 3);

    if (urlArray[0] !== "property_groups") {
        urlArray.splice(0, 1);
    }

    window.location.replace(`/${code}/${urlArray.join("/")}`);

}

const languages = [
    {
        code: "en",
        flag: ukFlag,
        name: "English"
    },
    {
        code: "fr",
        flag: franceFlag,
        name: "French"
    },
    {
        code: "es",
        flag: spainFlag,
        name: "Spanish"
    },
    {
        code: "de",
        flag: germanyFlag,
        name: "German"
    },
    {
        code: "el",
        flag: greeceFlag,
        name: "Greek"
    },
    {
        code: "it",
        flag: italyFlag,
        name: "Italian"
    },
    {
        code: "pt-pt",
        flag: portugalFlag,
        name: "Portuguese"
    }

];


/**
 * @param givenCode
 */
function getFlag(givenCode) {
    const language = languages.filter(({ code }) => (code === givenCode))
    return language[0] ;
}

/**
 * @returns {string} language code
 * get the current language from url
 */
function getCurrentLanguage() {
    const url = window.location.href.split("/");

    // in case we are on the initial english page there is no lagnuage code in the url
    if (url[3] === "h" || url[3] === "property_groups" || typeof url==="undefined") {
     return "en";
    }
   return url[3] || "en";
}

const noOp = () => { };

const addScript = (scriptSrc, callback = noOp, id = "", async = true, onload = noOp) => {
    const script = document.createElement("script");
    script.onload = onload;
    script.src = scriptSrc;
    script.async = async;
    script.id = id;
    document.body.appendChild(script);
    callback();
};

const removeScript = (id) => {
    const scriptList = document.querySelectorAll('script');
    const convertedNodeList = Array.from(scriptList);
    const script = convertedNodeList.find((script) => script.id === id);
    if (script) {
      script.parentNode.removeChild(script);
    }
  };

/**
 * @param rawText
 * @param words
 */
function replaceTranslatedProperties(rawText, ...words) {

    const specialCharactersRegEx = /%{[_a-z0-9]*}/;

    return rawText ? words.reduce((text, word) => {
        return text.replace(specialCharactersRegEx, word);
    }, rawText) : "";
}

export {
    changeLanguage,
    getCurrentLanguage,
    languages,
    getFlag,
    addScript,
    removeScript,
    replaceTranslatedProperties
}
